import React, { useRef, useEffect } from 'react';
export default function ({ data, isLoading, setPostVal }) {
	let { frequency, duration,seed } = data
	useEffect(() => {
		var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
		var oscillator = audioCtx.createOscillator();
		var gainNode = audioCtx.createGain();

		oscillator.connect(gainNode);
		gainNode.connect(audioCtx.destination);

		gainNode.gain.value = 1;
		oscillator.frequency.value = frequency;
		oscillator.type = "sine";

		oscillator.start();

		setTimeout(function () {
			oscillator.stop();
		}, duration);
	}, [seed]);
	return null;
}



