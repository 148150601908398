export default function processQueryString(search, qs) {
	var urlParams = new URLSearchParams(search);
	Object.keys(qs).map((k) => {
		var v = qs[k];
		if (v === null) {
			urlParams.delete(k);
		} else {
			urlParams.set(k, v);
		}
		return null;
	});
	var urlString = urlParams.toString()
		//.replace(/%27/gi, '\'')
		.replace(/%28/gi, '(')
		.replace(/%29/gi, ')')
		.replace(/%2c/gi, ',')
	//console.log('acb?', urlParams)
	if (urlString) return '?' + urlString;//.toString();
	return '';
}