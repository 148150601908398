/*let globals = {}

export function getVal(key){
    return globals[key] ?? null;
}

export function setVal(key,val){
    globals[key] = val;
}
*/
export let globals = {}