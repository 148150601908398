import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useKey, useLayer, triggerKey } from "./Keyboard"
import { StateContext, DispatchContext } from "./Contexts";
import { FaSpinner, FaPlus, FaMinus } from "react-icons/fa";
import QRCode from "qrcode.react";
import { getCoords, escapeRegExp, prettifyXml } from './functions'
import getHighlightedText from "./elements/getHighlightedText";
import Table from "./elements/Table";
import Select from "./elements/Select";
import Editor from "./elements/Editor";
import Switch from "./elements/Switch";
//import { useDropzone } from 'react-dropzone'
import ReactMarkdown from 'react-markdown'
//import QrScanner from 'qr-scanner'
import { QrReader } from 'react-qr-reader';
import { useZxing } from "react-zxing";
import { BarcodeFormat, DecodeHintType } from '@zxing/library';
import Html from './elements/Html';
import Beep from './elements/Beep';
import PreviewReport from './elements/PreviewReport';
import useOnScreen from './useOnScreen';
import { globals } from './globals';
import Resizer from "react-image-file-resizer";
import SignatureCanvas from 'react-signature-canvas'
export default function ({ def, data, update }) {
	const state = useContext(StateContext);
	const dispatch = useContext(DispatchContext);
	const { ismobile, isLoading } = state;
	const [visible, setVisible] = useState(false);
	const [lastFocus, setLastFocus] = useState(null);
	const [dontChangeFocus, setDontChangeFocus] = useState(false);
	const [apiActionPost, setApiActionPost] = useState({ values: {} });
	//const [apiActionPost, setApiActionPost] = useState();
	const cancel = (evt) => {
		setLastFocus(null);
		setVisible(false);
	}
	/*	const setLastFocus = (name)=>{
			apiActionPost.values['lastfocus'] = name;
	
		}*/
	//console.log(lastFocus);

	useEffect(() => { if (apiActionPost) apiActionPost.values['@lastFocus'] = lastFocus; }, [lastFocus])
	useEffect(() => {
		//let apiActionPostNew = {...apiActionPost}
		//console.log('post', apiActionPost);
		//console.log('data', data);
		//console.log('def', def);
		setVisible(true);
		let isUpdated = false;
		let apiActionPostNew;
		try {
			apiActionPostNew = JSON.parse(def[0]["v"])
			if (apiActionPostNew) isUpdated = true;
		} catch (e) {
			//console.log(e);
		}
		if (typeof apiActionPostNew !== 'object') apiActionPostNew = {}
		apiActionPostNew.values = apiActionPostNew.values ?? {}
		let nofocus = lastFocus === null
		//console.log('aa', data);
		data.map(item => {
			if (!item) return null
			let elem = JSON.parse(item.data) ?? {}
			if (elem.name && elem.elem !== 'Text' && elem.elem !== 'Button' && elem.elem !== 'Route') {
				let newval = elem.val ?? null;
				let newid = (newval && newval.id) ?? null;
				if (!nofocus && (elem.elem === 'Input' || elem.elem === 'Select') && newid !== null && elem.name === lastFocus) {
					nofocus = true;
					setLastFocus(null);
					//console.log('NULLLL',newid)
				}
				else if (nofocus && (elem.elem === 'Input' || elem.elem === 'Select') && newid === null) { nofocus = false; setLastFocus(elem.name); }
				if (elem.name === '@lastFocus' && newval !== lastFocus && !dontChangeFocus) { setLastFocus(newval); }
				if (newval !== null) {
					apiActionPostNew.values[elem.name] = newval;
					isUpdated = true;
				}
				if (newval === null && apiActionPostNew.values[elem.name]) {
					delete apiActionPostNew.values[elem.name];
					isUpdated = true;
				}
			}
			return null;

		});
		//if (nofocus)
		document.activeElement.blur();
		//console.log('aa', apiActionPost);
		//if (JSON.stringify(apiActionPostNew) !== JSON.stringify(apiActionPost)) {
		if (isUpdated) {
			//console.log(apiActionPostNew);
			setApiActionPost(apiActionPostNew);
		}
		//console.log(def, data);
		//}
	}, [data, def])

	//console.log('def', def,data);
	//	if (!apiActionPost) return null;
	const setPostVal = ({ name, value, post, api_multipart_form, modified }) => {
		let dcf = false
		//console.log('p', name, value, post, apiActionPost);
		//return
		if (value && typeof value === 'object' && value.id === undefined) dcf = true; //GB220629
		if (dontChangeFocus !== dcf) setDontChangeFocus(dcf);
		if (name) apiActionPost.values[name] = value;
		apiActionPost.values['@lastFocus'] = lastFocus;
		if (api_multipart_form && modified) {
			apiActionPost.api_multipart_form = api_multipart_form;
			apiActionPost.values['@modified'] = modified;
		}
		//console.log(apiActionPost);
		if (post) {
			//console.log('p', name, value, post);
			dispatch({ type: 'post_modal', apiActionPost });
		} else {
			//console.log('nay', name, value, post);
			setApiActionPost(apiActionPost);
		}
	}

	if (!visible) return null;


	let hasEscape = false;
	let hasGui = false;
	let cls = '';
	let style = '';
	let printStyle = '';
	let printMedia = '';
	let globalStyle = '';
	let windowTitle;
	let cannotLeaveModal = ismobile; //GB230922 mobiel nooit, desktop juist wel
	const onArrowKeys = (evt) => {
		//console.log('onArrowKeys', evt.key)
		if (evt.key === 'ArrowDown' || evt.key === 'ArrowUp' || evt.key === 'ArrowLeft' || evt.key === 'ArrowRight') {
			let found = 0;
			let elemArr = []
			document.querySelectorAll(".pickmodal INPUT:not([disabled])").forEach(elem => { elemArr.push(elem) });
			if (evt.key === 'ArrowLeft' && evt.target.selectionStart !== 0) return
			if (evt.key === 'ArrowRight' && evt.target.selectionEnd !== evt.target.value.length) return
			if (evt.key === 'ArrowUp' || evt.key === 'ArrowLeft') elemArr.reverse();
			let targetCoords = getCoords(evt.target)
			elemArr.forEach(elem => {
				if (found > 0) return
				let coords = getCoords(elem);
				switch (evt.key) {
					case 'ArrowUp': {
						if (coords.top < targetCoords.top && Math.round(coords.left / 16) === Math.round(targetCoords.left / 16)) found = 2
						break;
					}
					case 'ArrowDown': {
						if (coords.top > targetCoords.top && Math.round(coords.left / 16) === Math.round(targetCoords.left / 16)) found = 2
						break;
					}
					case 'ArrowLeft': {
						if (coords.top === targetCoords.top && coords.left < targetCoords.left) found = 2
						break;
					}
					case 'ArrowRight': {
						if (coords.top === targetCoords.top && coords.left > targetCoords.left) found = 2
						break;
					}
				}
				if (found === 2) {
					elem.focus();
					elem.select();
				}

			})
			evt.preventDefault();
			evt.stopPropagation();
		}
	}
	let allButtonGroups = {}
	const rows = data.map((item, idx) => {
		if (!item) return null
		let elemdata = JSON.parse(item.data) ?? {}
		const { elem } = elemdata;
		if (elemdata.key && elemdata.key.toLowerCase() === 'escape') hasEscape = true;
		//if (elemdata.name && elemdata.name[0] === '@') elemdata.name = elemdata.name.toLowerCase();
		const itemparams = { /*key: item.id,*/ data: elemdata, ismobile, setPostVal, isLoading, dispatch, onArrowKeys, setLastFocus, lastFocus }
		//console.log(elem, apiActionPost);
		if (elem === 'Modal') {
			cls += elemdata.cls ? ' ' + elemdata.cls : '';
			style = elemdata.style ?? '';
			printStyle = elemdata.printStyle ?? '';
			printMedia = elemdata.printMedia ?? '';
			globalStyle = elemdata.globalStyle ?? '';
			windowTitle = elemdata.windowTitle ?? null;
			if (elemdata.cannotLeaveModal && elemdata.cannotLeaveModal == ! null) cannotLeaveModal = elemdata.cannotLeaveModal ?? false;
			return null;
		}
		if (elem === 'Value') return null;
		if (elem === 'dispatch') {
			const { json } = elemdata;
			data[idx] = null
			if (json) dispatch(json);
			return null
		}
		//let key = elemdata.name ?? 't' + idx
		let key = (elemdata.name ?? 't') + '_' + idx
		//console.log(key, elemdata.val);
		hasGui = true;
		if (elemdata.type && elemdata.type === "hidden") hasGui = false;
		if (itemparams.data.group) {
			if (allButtonGroups[itemparams.data.group]) {
				allButtonGroups[itemparams.data.group].push({ ...itemparams, isSubButtongroupButton: true })
				return null
			} else {
				allButtonGroups[itemparams.data.group] = []
				return <Buttongroup key={key} {...itemparams} buttons={allButtonGroups[itemparams.data.group]} dispatch={dispatch} />
			}
		}
		//console.log(elem, itemparams.data.group);
		if (elem === 'Image') return <Image key={key} {...itemparams} />
		if (elem === 'Refresh') return <Refresh key={key} {...itemparams} />
		if (elem === 'Text') return <Text key={key} {...itemparams} />
		if (elem === 'Html') return <Html key={key} {...itemparams} />
		if (elem === 'Switch') return <Switch key={key} {...itemparams} />
		if (elem === 'Beep') return <Beep key={key} {...itemparams} />
		if (elem === 'PreviewReport') return <PreviewReport key={key} {...itemparams.data} />
		if (elem === 'Input') return <Input key={key} {...itemparams} />
		if (elem === 'Select') return <Select key={key} {...itemparams} />
		if (elem === 'Editor') return <Editor key={key} {...itemparams} />
		if (elem === 'QRCode') return <QRCodeCustom key={key} {...itemparams} />
		if (elem === 'QRScanner') return <QRScanner key={key} {...itemparams} />
		if (elem === 'BarcodeQRScanner') return <BarcodeQRScanner key={key} {...itemparams} />

		if (elem === 'Placeholder') return null;

		if (elem === 'FileInput') return <FileInput key={key} {...itemparams} />
		if (elem === 'FileInputMulti') return <FileInputMulti key={key} {...itemparams} dispatch={dispatch} />
		if (elem === 'Button') return <Button key={key} {...itemparams} />
		if (elem === 'Route') return <Route key={key} {...itemparams} dispatch={dispatch} />
		if (elem === 'Signature') return <Signature key={key} {...itemparams} />
		if (elem === 'Table') {
			let params = itemparams.data;
			params.def = { ...params }//{ cls: params.cls, print: params.print, printonly: params.printonly }
			return <Table key={key} {...params} setPostVal={setPostVal} isLoading={isLoading} />
		}
		if (elem === 'ActionButton') return <ActionButton key={key} {...itemparams} dispatch={dispatch} />
		return <strong>{'Unknown element ' + (elem ?? '["elem" not specified]')}</strong>;
	});
	if (!hasGui) {
		dispatch({ type: 'hide_modal' });
		return null;
	}

	return (
		<Modal onCancel={cancel} cls={cls} ismobile={ismobile} style={style} printStyle={printStyle} printMedia={printMedia}
			windowTitle={windowTitle} globalStyle={globalStyle} hasEscape={hasEscape}
			cannotLeaveModal={cannotLeaveModal}>
			<div className="form-group">
				{/*
				<div style={{ position: 'relative' }}>
					{isLoading ? <FaSpinner size={24} style={{ textShadow: '0 2px 5px #ffffff80', position: 'absolute', top: '10px', right: '2px' }} className="fa-spin" /> : ''}
				</div>
				*/}
				{rows}
			</div>
		</Modal>
	);
}
function QRCodeCustom({ data }) {
	if (data.element_id) {
		setTimeout(() => {
			let elem = document.getElementById(data.element_id)
			if (!elem) return
			data.size = elem.offsetHeight ? elem.offsetHeight : data.size;
			//console.log(elem.style.height);
			ReactDOM.render(
				<QRCode {...data} />,
				document.getElementById(data.element_id)
			);

		}, 250)
		return null;
	}
	return <div className={data.class}><QRCode {...data} /></div>
}
function Refresh({ data, setPostVal, isLoading }) {
	let { show, timeout, show_text } = data;
	const [counter, setCounter] = useState(timeout);
	useEffect(() => {
		//if (counter !== timeout) 
		setCounter(timeout);
	}, [timeout]);
	useEffect(() => {
		if (isLoading) {
			setCounter(timeout);
			return
		}
		let ts2 = setTimeout(() => {
			if (counter < 1) {
				setPostVal({ post: true });
				setCounter(timeout);
			} else {

				setCounter(counter - 1);
			}
		}, 1000);
		return () => {
			clearTimeout(ts2);
		}
	}, [counter, isLoading]);
	if (!show) return <></>
	return <>{show_text.replace("{seconds}", isLoading ? 0 : counter)}</>
}

function Text({ data, dispatch }) {
	let { cls, disp, print, printonly, markdown } = data;
	if (cls === 'json') {
		cls = 'code pre'
		try {
			let obj = JSON.parse(disp)
			disp = JSON.stringify(obj, null, 4)
		} catch (e) {
			disp = "INVALID JSON:\r\n\r\n" + disp
		}
	}
	if (cls === 'xml') {
		cls = 'code pre'
		try {
			disp = prettifyXml(disp);
		} catch (e) {
			disp = "INVALID XML:\r\n\r\n" + disp
		}
	}
	if (print) cls += ' print'
	if (printonly) cls += ' printonly'

	const rightClick = (evt) => {
		if (data.trans === false) return
		dispatch({ type: 'rename', context: data.language_context ?? 'modal', display: data.disp, name: data.name });
		evt.preventDefault();
	}
	if (data.highlight) disp = getHighlightedText(disp, data.highlight);
	return <div onContextMenu={rightClick} className={(data.inline ? 'inline w-24 m-1 ' : '') + cls}>{markdown ? <ReactMarkdown>{disp}</ReactMarkdown> : disp}</div>
}
function Image({ data }) {
	let { cls, disp, print, printonly, markdown } = data;
	if (print) cls += ' print'
	if (printonly) cls += ' printonly'

	return <div className={(data.inline ? 'inline w-24 m-1 ' : '') + cls}><img {...data} /></div>
}
function Input({ data, setPostVal, isLoading, onArrowKeys, setLastFocus, lastFocus }) {
	const ref = useRef(null);
	let { cls } = data;
	if (cls === 'xml') {
		cls = 'code pre'
		try {
			data.val = prettifyXml(data.val);
		} catch (e) {
			console.log(e);
		}
	}
	const [value, setValue] = useState(data.val);
	const change = (evt) => {
		let val = evt.target.value
		let post = false
		if (data.scanner) {
			let lines = evt.target.value.split(/[\r\n]+/gi)
			if (lines[lines.length - 1].length === 0) {
				//[...new Set(items)]
				let lastline = lines[lines.length - 2];
				//console.log(lines[lines.length-2])
				//console.log([...new Set(lines)])
				let match;
				if (lastline) {
					if (match = lastline.match(new RegExp("^" + escapeRegExp(data.scanner_action_prefix) + "(.+)=(.*$)", 'i'))) {

						console.log('SCAN ACTION:', match[1], match[2]);
						// https://tsql.app#@button=Slapen
						setPostVal({ name: match[1], value: match[2] === '' ? null : match[2], post: true });
						lines.pop();
						lines.pop();
						lines.push('');
					} else {
						if (data.scanner_direct_post) post = true;
					}
				}
				val = [...new Set(lines)].join("\r\n");
			}
		}

		//console.log(evt.key);
		setPostVal({ name: data.name, value: val, post: val !== value && post })
		setValue(val);
	}
	useEffect(() => {
		setValue(data.val); //gb221122
	}, [data.val]);
	//useEffect(() => {
	//	if (false && data.val !== value) setValue(data.val)
	//}, [ data])
	useEffect(() => {
		//if (!isLoading && data.val !== value) setValue(data.val)
		if (!isLoading && ref.current && ref.current.focus && lastFocus === data.name) {
			ref.current.focus();
			if (ref.current.select && data.select) ref.current.select();
		}
		//if (!isLoading && ref.current && ref.current.focus && (data.focus || lastFocus === data.name)){
		//	ref.current.focus();
		//	if(data.select) ref.current.select();
		//}
		//if (!isLoading && ref.current && ref.current.select && data.select) ref.current.select();
	}, [isLoading, data]) //201019
	let attribs = {
		disabled: data.type === "disabled"
		, readOnly: isLoading
		, ignore_keys: data.ignore_keys //(data.type === 'textarea') ? 'enter' : null
		, type: data.type ? data.type : "text"
		, onKeyDown: (data.type === 'textarea') ? (e) => { } : onArrowKeys
		, ref: ref
		, onFocus: () => setLastFocus(data.name)
		, autoComplete: data.autocomplete ?? "off"
		, maxLength: data.max ? data.max : 4000
		, min: data.number_min //gb230501
		, max: data.number_max //gb230501
		, value: value || value === 0 ? value : ''
		, lang: data.lang ?? null
		, onChange: change
		, className: (data.inline ? 'inline w-24 m-1 ' : 'mb-3 ') + data.cls + ' form-control'
		, placeholder: data.disp
		, rows: data.rows
		, style: data.scanner && !data.scanner_visible ? {
			position: 'absolute !important'
			, height: '0px'
			, width: '0px'
			, overflow: 'hidden'
			, clip: 'rect(0px,0px,0px,0px)'
			, opacity: 0
		} : null
	}
	if (attribs.type === 'checkbox') {
		attribs.checked = (value === "1");
		attribs.onMouseDown = (evt) => evt.preventDefault();
		attribs.onChange = (evt) => {
			let val = value === "1" ? "0" : "1"
			setPostVal({ name: data.name, value: val, post: false });
			setValue(val);
		}
	}
	let scanner_items_text = '';
	if (data.scanner) {
		let scanned_items = value ? value.split(/[\r\n]+/g).length - 1 : 0;
		scanner_items_text = data.scanner && scanned_items ? data.scanner_items_text.replace("{n}", scanned_items) : '';
	}
	let inp
	if (attribs.type === 'textarea') {
		inp = <textarea {...attribs} />
	} else if (attribs.type === 'number') {
		attribs.type = 'text'
		const plusMin = (v) => {
			let min = data.number_min ?? 0;
			let max = data.number_max ?? 100;
			let num = parseFloat(value) || min;
			//			console.log(num);
			num += v;

			if (min > num) num = min;
			if (max < num) num = max;
			setPostVal({ name: data.name, value: num, post: false })
			setValue(num);
			//ref.current.value = num
		}
		//console.log(attribs);
		//attribs.className += ' input-number outline-none'
		let step = data.number_step ?? 1;
		inp = (<div className={(data.inline ? 'inline w-24 m-1 ' : 'mb-3 ') + data.cls}>
			<div className="input-group">
				<span className="input-group-prepend">
					<button type="button" className="btn btn-outline-secondary btn-number outline-none btn-plus-min" onClick={() => plusMin(-step)}>
						<FaMinus />
					</button>
				</span>
				<input  {...attribs} className="input-number outline-none form-control text-right" />
				<span className="input-group-append">
					<button type="button" className="btn btn-outline-secondary btn-number outline-none btn-plus-min" onClick={() => plusMin(step)}>
						<FaPlus />
					</button>
				</span>
			</div></div>);
	} else {
		inp = <input {...attribs} />
	}
	/*	disabled={data.type === "disabled"}// || isLoading}
		readOnly={isLoading}
		type={data.type ? data.type : "text"}
		onKeyDown={onArrowKeys}
		ref={ref}
		onFocus={() => setLastFocus(data.name)}
		autoComplete="off"
		maxLength={data.max ? data.max : 4000}
		value={value ? value : ''}
		onChange={change}
		className={(data.inline ? 'inline w-24 m-1 ' : 'mb-3 ') + data.cls + ' form-control'}
		placeholder={data.disp} 
		*/
	if (data.inline) {
		//className={data.cls + ' btn m-1 w-24'}
		return inp
	} else {
		return <div>
			{data.scanner && data.scanner_visible ? 'scanner_action_prefix: ' + data.scanner_action_prefix : ''}
			{inp}
			{scanner_items_text}
		</div>

	}
}
function FileInputMulti({ data, setPostVal, isLoading, dispatch }) {
	const ref = useRef(null);
	const [filename, setFilename] = useState('');
	let { message } = data;
	if (!message) message = "Drop your files here, or click to add them";
	const change = (evt) => {
		let files = evt.target.files;
		if (!files) return;
		//console.log(files);
		//console.log(files.FileList);
		let api_multipart_form = new FormData()
		//let values = {}
		let modified = [];
		for (const file of files) {

			if (file.size < (data.filesize_limit ?? 2147483648)) {
				let filedate = (file.lastModifiedDate ?? (new Date())).toISOString() //iOS werkt niet met lastModifiedDate
				api_multipart_form.append('files', file)
				modified.push({ filename: file.name, modified: filedate });
			} else {
				dispatch({ type: 'toasts', toasts: [{ id: 1, data: "{\"name\":\"file_size\",\"cls\":\"bg-danger text-white\",\"disp\":\"" + file.name + ": File too big\",\"secs\":5}" }] });
			}

		}

		setPostVal({
			name: data.name, value: '-1', api_multipart_form: api_multipart_form, modified: modified, post: true
		})

	}
	useEffect(() => {
		if (!isLoading && ref.current && ref.current.focus && data.focus) ref.current.focus()
	}, [isLoading, data])
	const addClass = (e) => { e.target.parentElement.classList.add("dragover") }
	const remClass = (e) => { e.target.parentElement.classList.remove("dragover") }
	return <div className={data.cls}>
		<label
			className="fileupload"
		><input
				//disabled={isLoading}
				onDragEnter={addClass}
				onDragEnd={remClass}
				onDragLeave={remClass}
				onDrop={remClass}
				onDragExit={remClass}

				autoComplete="off"
				type="file" name="a345345" id="a345345" multiple ref={ref} autoFocus={data.focus} maxLength={data.max ? data.max : 4000} /* value={value ? value : ''}*/
				onChange={change} className={data.cls + ' mb-3'} placeholder={data.disp} />
			{message}
		</label>
		{filename}
	</div>
}
function FileInput({ data, setPostVal, isLoading }) {
	const ref = useRef(null);
	//const [value, setValue] = useState(data.val);
	/*
	const readFile = (file) => {
		return new Promise((resolve, reject) => {
			var fr = new FileReader();
			fr.onload = () => {
				resolve(fr.result)
			};
			fr.readAsText(file.blob);
		});
	}
	*/
	const [filename, setFilename] = useState('');

	const change = (evt) => {


		for (let i = 0; i < evt.target.files.length; i++) {
			const file = evt.target.files[i];
			let filedate = (file.lastModifiedDate ?? (new Date())).toISOString()
			let mimetype = file.type;
			//console.log(mimetype);
			if (data.resize_max && mimetype === 'image/jpeg') {
				Resizer.imageFileResizer(
					file,
					data.resize_max,
					data.resize_max,
					"JPEG",
					data.resize_quality,
					0,
					(uri) => {
						let base64 = uri.split(';base64,', 2)[1]
						setPostVal({
							name: data.name, value: {
								name: file.name
								, mimetype: mimetype
								, modified: filedate
								, data: base64
							}, post: true
						})

					},
					"base64",
					200,
					200
				);
			} else {
				const reader = new FileReader();
				reader.addEventListener('load', (evt) => {
					let base64 = evt.target.result.split(';base64,', 2)[1]
					setPostVal({
						name: data.name, value: {
							name: file.name
							, mimetype: mimetype
							, modified: filedate
							, data: base64
						}, post: true
					})
				});
				reader.readAsDataURL(file);
			}
		}
		return

	}
	useEffect(() => {
		if (!isLoading && ref.current && ref.current.focus && data.focus) ref.current.focus()
	}, [isLoading, data])
	return <div className={data.cls}><input
		//disabled={isLoading}
		autoComplete="off"
		type="file"
		//multiple
		ref={ref} autoFocus={data.focus} maxLength={data.max ? data.max : 4000} /* value={value ? value : ''}*/
		onChange={change} className={data.cls + ' mb-3'} placeholder={data.disp} />
		{filename}
	</div>
}
function Signature({ data, setPostVal }) {
	const ref = useRef(null);
	return <div>
		<SignatureCanvas ref={ref} {...data} onEnd={(e) => {
			if (!ref.current.isEmpty()) {
				let d = ref.current.getCanvas().toDataURL().replace(/^data:.*?;base64,/, '');
				setPostVal({ name: data.name, value: d, post: false });
			}
		}} />
	</div>
}

function Route(props) {
	const setPostVal = ({ evt }) => {
		const { data, dispatch } = props;
		let pn = data.val
		let se = data.search
		if (!pn) {
			pn = window.location.pathname
			se = window.location.search
		}
		if (evt.ctrlKey) {
			var win = window.open(pn + se, '_blank');
			return true
		} else {
			dispatch({ type: 'pathname', pathname: pn, search: se/*, title: item.disp*/ });
		}
		//dispatch({ type: "pathname", pathname: data.val, search: data.search });
	}
	return <Button {...props} setPostVal={setPostVal} />
}
function ActionButton(props) {
	const setPostVal = () => {
		const { data, dispatch } = props;
		//console.log(data);
		dispatch({ ...data });
	}
	return <Button {...props} setPostVal={setPostVal} />
}
function Button({ data, ismobile, setPostVal, dispatch, isLoading, isMainButtongroupButton, isSubButtongroupButton, isMainButtongroupButtonOneOnly }) {
	//const [enabled,setEnabled] = useState(true);
	let { invisible, language_context } = data;
	const ref = useRef(null);
	const click = (evt) => {
		if (isLoading) return;
		let thisName = data.name;
		let thisValue = data.val;

		if (data.set_values) {
			//console.log(data.set_values);
			let set_values = {}
			try {
				set_values = JSON.parse(data.set_values)
			} catch (e) {
				alert('BAD JSON: ' + data.set_values);
				set_values = {}
			}
			//console.log(set_values);
			Object.keys(set_values).forEach((name) => {
				let value = set_values[name];
				//let matches = pair.match(/^([^=]+)(=?)(.*)$/i)
				//let name = matches[1];
				//let value = matches[2] === "=" ? matches[3] : null;
				//let [all, name, value] = pair.match(/^([^=]+)=?(.*)$/gi);
				//console.log(name, value);
				if (thisName === name) thisValue = value;
				setPostVal({ name: name, value: value })
			})
		}
		setPostVal({ name: thisName, value: thisValue, post: true, evt })
		evt.preventDefault();
		evt.stopPropagation();
		// alert(data.val);
	}
	let cls = 'btn ' + data.cls
	//console.log(data.inline);
	if (data.inline !== false) cls = 'm-1 w-24 ' + cls
	//	let cls = data.cls + ' btn m-1 w-24'
	//	if (data.inline===0)
	let layer = (isMainButtongroupButton && !isMainButtongroupButtonOneOnly) || isSubButtongroupButton ? 'modalsub' : 'modal'
	if (isMainButtongroupButton) cls = "btn w-75 " + data.cls;
	if (isMainButtongroupButtonOneOnly) cls = "btn w-100 " + data.cls;
	if (isSubButtongroupButton) cls = "dropdown-item text-truncate px-2";
	useKey(data.key, click, [], layer);
	if (invisible) return <></>

	const rightClick = (evt) => {
		if (data.trans === false) return
		dispatch({ type: 'rename', context: data.language_context ?? 'modal', display: data.disp, name: data.elem === 'Button' ? data.val : data.dispname ?? data.name });
		evt.preventDefault();
	}

	return (
		<>
			<button
				ref={ref}
				disabled={isLoading}
				onClick={click}
				onContextMenu={rightClick}
				title={data.disp}
				//onKeyDown={click}
				className={cls}>{data.disp}</button>
			{!isMainButtongroupButton && !ismobile && data.key ? <div className={"keycode" + (isSubButtongroupButton ? " keycode-btn-group" : "")}>{data.key}</div> : ''}
		</>
	);
}
function Buttongroup({ data, ismobile, setPostVal, isLoading, buttons, dispatch }) {
	const [show, setShow] = useState(false);
	let itemparams = { data, ismobile, setPostVal, isLoading, isMainButtongroupButton: true }
	let keycode = data.key
	useKey(show || buttons.length === 0 ? false : keycode, () => setShow(true), [], 'modal');
	useKey(show && buttons.length > 0 ? "Escape" : false, () => setShow(false), [], 'modalsub');
	if (buttons.length === 0) {
		itemparams = { data, ismobile, setPostVal, isLoading }
		if (data.elem === 'Route') return <Route {...itemparams} dispatch={dispatch} />
		if (data.elem === 'Button') return <Button {...itemparams} />
	}
	let cls = data.cls
	return <>
		<div className="btn-group m-1 w-24"
			onMouseLeave={() => setShow(false)}
		//onMouseLeave={() => setTimeout(() => setShow(false), 300)}
		>
			{data.elem === 'Route' ? <Route {...itemparams} dispatch={dispatch} /> : ''}
			{data.elem === 'Button' ? <Button {...itemparams} /> : ''}
			{/* <button type="button" className={"btn w-75 " + cls}>Action</button> */}
			{buttons.length > 0 ? <>
				<button type="button" disabled={isLoading} className={"btn dropdown-toggle dropdown-toggle-split " + cls}
					onClick={() => setShow(!show)}
				//onBlur={() => setTimeout(() => setShow(false), 100)}
				>
					<span className="sr-only">Toggle Dropdown</span>
				</button>
				{show ? <ButtongroupDown data={data} buttons={buttons} dispatch={dispatch} /> : ''}
			</>
				: ''}
		</div>
		{!ismobile && data.key ? <div className={"keycode" + (show ? ' z-1200' : '')}>{data.key}</div> : ''}
	</>
}

function BarcodeQRScanner({ data, setPostVal }) {
	const [result, setResult] = useState("");
	const [result2, setResult2] = useState("");
	const { style, width, height, scanDelay, videoStyle } = data;
	const hints = new Map();
	const formats = [BarcodeFormat.CODE_128];
	hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
	const { ref } = useZxing({
		onResult(result) {
			setResult(result.getText());
		},
		/*hints: hints,*/
		timeBetweenDecodingAttempts: scanDelay
	});
	if (ref && ref.current && ref.current) {
		if (result2 !== "stream") setResult2("stream");
	}
	//console.log(ref && ref.current && ref.current.srcObject)
	return (
		<>
			<video ref={ref} style={videoStyle} videoWidth={width} videoHeight={height}
				width={width ?? "100%"} height={height ?? "50%"}
			/>
			<p>
				<span>Last result:</span>
				<span>{result}</span>
				<pre>{result2}</pre>
			</p>
		</>
	);
};
function QRScanner({ data, setPostVal }) {
	const [val, setVal] = useState(data.val);
	const [lastVal, setLastVal] = useState(data.val);
	if (val !== lastVal) {
		setPostVal({ name: data.name, value: val, post: true })
		setLastVal(val);
	}
	let className = data.className ?? null;
	let scanDelay = data.scanDelay ?? null;
	let containerStyle = data.containerStyle ?? null
	let videoContainerStyle = data.videoContainerStyle ?? null;
	let videoStyle = data.videoStyle ?? null;
	//if (hidden) return null
	return (
		<>
			<QrReader
				constraints={{ facingMode: 'environment' }}
				onResult={(result, error) => {
					if (!!result) {
						let v = result?.text
						if (val !== v) setVal(v);
					}

					if (!!error) {
						console.info(error);
					}
				}}
				scanDelay={scanDelay}
				className={className}
				containerStyle={containerStyle}
				videoContainerStyle={videoContainerStyle}
				videoStyle={videoStyle}
			/>
		</>
	);
}
function ButtongroupDown({ data, buttons, dispatch }) {
	/*const ref = useRef()
	const [topClass, setTopClass] = useState('')
	const ratioOnScreen = useOnScreen(ref)
	useEffect(() => {
		if (ratioOnScreen !== false && ratioOnScreen !== 1) setTopClass('top');
	}, [ratioOnScreen])
	//console.log(ratioOnScreen);
	*/
	useLayer('modalsub');//" + topClass + "
	//ref={ref}
	return <div className={"dropdown-menu w-100 show mt-0"}>
		{
			buttons.map((itemparams, i) => {
				//console.log(itemparams)
				if (data.elem === 'Route') return <Route key={'r' + i} {...itemparams} dispatch={dispatch} />
				if (data.elem === 'Button') return <Button key={'r' + i} {...itemparams} />
				return <a className="dropdown-item">UNKNOWN DROP ITEM</a>
			})
		}
	</div >
}

function Modal({ children, onCancel, hasEscape, cls, printStyle, style, printMedia, globalStyle, windowTitle, cannotLeaveModal }) {
	const cancel = (evt) => {
		//if (cannotLeaveModal) return;
		if (onCancel) onCancel(evt);
		evt.preventDefault();
	}
	const [tempTitle, setTempTitle] = useState(document.title);
	const ref = useRef(null);
	useLayer('modal');
	useKey(hasEscape ? false : "escape", cancel, [], 'modal');
	/*useEffect(() => {
		if (ref.current && ismobile) setTimeout(() => { ref.current.click() }, 500);
	}, [children])
	*/
	if (windowTitle) document.title = windowTitle;
	useEffect(() => {
		globals.inModal = true;
		return () => {
			document.title = tempTitle;
			globals.inModal = false;
		}
	}, []);
	return (
		<>{globalStyle ? <style>{globalStyle}</style> : ''}
			<style>{"@media print {" + printMedia + " body > div > div > div:not(.pickmodal) { display: none;	} body { overflow-y: auto;  } ::-webkit-scrollbar {width: 0px;} .container{ background-color: #fff !important} .pickmodal{" + (printStyle ?? '') + "}} \
			.pickmodal{"+ (style ?? '') + "}"}</style>
			<div className={'pickmodal ' + (cls ? cls : '')}
				onContextMenu={evt => { if (!cannotLeaveModal) triggerKey("Escape") }} onMouseDown={evt => { if (!cannotLeaveModal) triggerKey("Escape") }}>
				<div
					onMouseDown={e => e.stopPropagation()} onContextMenu={e => e.stopPropagation()}>
					{children}
				</div>
			</div>
		</>
	);
}