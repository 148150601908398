import React, { useState, useEffect } from 'react';
import { FaTable, FaPlay, FaLayerGroup } from 'react-icons/fa';
import { GrTable } from 'react-icons/gr';
import { AiOutlineFunction, AiOutlineConsoleSql } from 'react-icons/ai';
let URL1 = "https://232.tracy.nu/apidev/v1/editor_objects"
let URL2 = "https://232.tracy.nu/apidev/v1/editor_objects_params"

let SQL_OBJECTS;
let SQL_OBJECTS_PARAM;
let SQL_OBJECTS_MERGED;
let SQL_OBJECTS_PARAMS = {};
let timeoutRunning;
export default function EditSense({ data, setPostVal, isLoading, onArrowKeys, setLastFocus, lastFocus }) {
	const [listTop, setListTop] = useState(0);
	const [listLeft, setListLeft] = useState(0);
	const [listItems, setListItems] = useState([]);
	const [usedObjects, setUsedObjects] = useState([]);
	const [listIndex, setListIndex] = useState(-1);
	const [search, setSearch] = useState();
	const [value, setValue] = useState(data);
	const [sqlObjects, setSqlObjects] = useState(SQL_OBJECTS)
	useEffect(() => {
		if (SQL_OBJECTS !== undefined) return;

		(async () => {
			const result = await fetch(URL1);
			SQL_OBJECTS = (await result.json()).data;
			SQL_OBJECTS.forEach(item => {
				item.escaped = '(^|[^a-z0-9_])' + item.name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').toLowerCase() + '($|[^a-z0-9_])';
				return item;
			});
			//console.log(SQL_OBJECTS)
			setSqlObjects(SQL_OBJECTS);
		})();
	}, []);
	const mergeObjects = () => {
		let merged = [...SQL_OBJECTS, ...SQL_OBJECTS_PARAM];
		merged.sort((a, b) => { return a.prio > b.prio ? -1 : 1; })
		setSqlObjects(merged);
		//console.log(merged)
	}
	const checkObjects = (text) => {
		if (timeoutRunning) clearTimeout(timeoutRunning)
		timeoutRunning = setTimeout(() => {
			let lowertext = text.toLowerCase()
			let objectIds = sqlObjects.filter((item) => {
				return item.id !== 0 && lowertext.match(item.escaped)
			})
			setUsedObjects(objectIds)
			let ids = ''
			objectIds.forEach(item => { if (item.id > 0) ids += ',' + item.id });
			if (ids === '') {
				setSqlObjects(SQL_OBJECTS);
				return
			}
			if (SQL_OBJECTS_PARAMS[ids]) {
				SQL_OBJECTS_PARAM = SQL_OBJECTS_PARAMS[ids];
				mergeObjects();
				return;
			}
			//console.log(ids);
			(async () => {
				const result = await fetch(URL2 + '?ids=' + ids);
				SQL_OBJECTS_PARAMS[ids] = (await result.json()).data ?? [];
				SQL_OBJECTS_PARAMS[ids].forEach(item => {
					item.escaped = '(^|[^a-z0-9_])' + item.name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').toLowerCase() + '($|[^a-z0-9_])';
					return item;
				});

				SQL_OBJECTS_PARAM = SQL_OBJECTS_PARAMS[ids] ?? [];
				mergeObjects();
			})();
		}, 1000)
	}
	const sense = (evt) => {
		let textarea = evt.target

		let text = textarea.value
		let s = textarea.selectionStart
		let e = textarea.selectionEnd
		let part = text.substring(0, s > e ? s : e).replace(/[\t]/gi, "    ")
		let sp = part.split(/[\r\n]/gi)
		let last = sp.length > 0 ? sp[sp.length - 1] : part;
		let search = last.match(/[a-z0-9_]+$/gi)
		if (search) {
			search = search[0].toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
			setSearch(search)
			let sqlObjectsList = sqlObjects.filter((item) => {
				return item.name.toLowerCase().match(search)
			})
			setListTop(sp.length * 18 - textarea.scrollTop + 4);
			setListLeft((last.length - search.length) * 6.585 - textarea.scrollLeft);
			setListIndex(-1);
			setListItems(sqlObjectsList.slice(0, 10))
			//console.log(search,sqlObjectsList[0])
		} else {
			setSearch()
			setListItems([]);
		}

		setValue(text);
		checkObjects(text);
	}
	//console.log(listIndex,'li')
	const keyDown = (evt) => {
		if (listItems.length) {
			let textarea = evt.target
			let s = textarea.selectionStart
			let e = textarea.selectionEnd
			let begin = s > e ? e : s;
			if (evt.key === "ArrowUp") setListIndex(listIndex > 0 ? listIndex - 1 : listItems.length - 1);
			if (evt.key === "ArrowDown") setListIndex(listIndex < listItems.length - 1 ? listIndex + 1 : 0);
			if (evt.key === "Enter" && listIndex !== -1) {
				textarea.setRangeText(listItems[listIndex].name, begin - search.length, begin, 'end');
				setValue(textarea.value); setListItems([]); checkObjects(textarea.value);
				evt.preventDefault();
			}
			if (evt.key === "ArrowLeft" || evt.key === "ArrowRight" || evt.key === "Escape") { setListItems([]); }
			if (evt.key === "ArrowUp" || evt.key === "ArrowDown" || evt.key === "Escape") evt.preventDefault();
		}
	}



	return <>{listItems.length ?
		<div className="position-absolute editor" style={{
			zIndex: 10,
			backgroundColor: '#fc8e'
			, borderRadius: '4px'
			, boxShadow: '0 4px 8px rgba(0,0,0,0.5)'
			, marginLeft: listLeft + 'px', marginTop: listTop + 'px', border: "0px solid #ccc", padding: "4px 0"
		}} >
			{listItems.map((item, i) => {
				let icon = <AiOutlineConsoleSql size={12} />

				if (item.type === 'FN') icon = <AiOutlineFunction size={12} />
				if (item.type === 'TF') icon = <AiOutlineFunction size={12} />
				if (item.type === 'U') icon = <GrTable size={12} />
				if (item.type === 'V') icon = <FaLayerGroup size={12} />
				if (item.type === 'P') icon = <FaPlay size={12} />
				return <div key={i} style={{ padding: "0 4px" }} className={listIndex === i ? "bg-danger-50" : ""}>{icon} <b>{item.name}</b> {item.id === 0 ? <i>{item.type}</i> : ''}</div>;
			})}</div> : null}
		<textarea
			rows={10}
			spellCheck="false"
			onKeyDown={keyDown}
			style={{}}
			className={"form-control editor"}
			value={value}
			onChange={(evt) => sense(evt)}
		/>
		{/*<div>{usedObjects.map((item, i) => { return item.name + ' ' })}</div>*/}
	</>;
}
/*

const getCaretPos = (e) => {

	// Getting the textarea element
	let textarea = document.getElementById('textarea');

	// Adjusting textarea to prevent scrolling
	textarea.style.height = `${e.target.scrollHeight}px`

	// Appending element to the DOM after textarea
	textarea.insertAdjacentHTML('afterend', `<span id = 'dummy'>${textarea.value}</span>`);

	// Getting position info of the rectangles inside dummy element
	let rectangles = document.getElementById('dummy').getClientRects();
	let last = rectangles[rectangles.length - 1];

	// Getting position info of the textarea
	let text = document.getElementById('textarea').getBoundingClientRect();

	// Setting coordinates
	let x = last.x + last.width;
	let y = text.y + text.height - last.height;

	// Removing dummy
	dummy.remove();

	// Returning variables
	return [x, y];

}

*/

//https://232.tracy.nu/apidev/v1/editor_objects