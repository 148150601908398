import React, { useState, useEffect, useContext } from 'react';
import { DispatchContext } from "./Contexts";

export default function ({ toasts, edit }) {
	const toastdivs = toasts.map((toast) => {
		//console.log(toast.mt)
		let data
		try {
			data = JSON.parse(toast.data.replace(/\r/gi, '').replace(/\n/gi, "\\r\\n")) ?? {}
		} catch (e) {
			console.log(e);
			//console.log(toast.data.replace(/\r\n/gi,"\\r\\n"));
			//alert(toast.data);
			data = { secs: 30, cls: "bg-danger text-white", disp: "ERROR IN TOAST: " + toast.data }
		}
		return <Toast key={'t' + toast.mt} data={data} />
	})

	return <div style={{ position: 'absolute', zIndex: 1122, bottom: 0, right: 0, left: edit ? 0 : 'auto', padding: '8px' }}>{toastdivs}</div>
}
function Toast({ data }) {
	const dispatch = useContext(DispatchContext);
	//console.log(data);
	const [visible, setVisible] = useState(true);
	const { secs, audio_url, disp, vibrate, speech, lang } = data;

	useEffect(() => {
		let timeout = setTimeout(() => setVisible(false), secs ? secs * 1000 : 5000)
		return () => clearTimeout(timeout);

	}, []);
	useEffect(() => {
		if (window.navigator.vibrate && vibrate && vibrate > 0) {
			console.log("Vibrate: ", vibrate)
			window.navigator.vibrate(vibrate);
		}
	}, [vibrate]);
	useEffect(() => {
		if (speech) {
			const synth = window.speechSynthesis;
			console.log("Speech voices: ", speech, synth.getVoices())
			let utterance = new SpeechSynthesisUtterance(speech);
			if (lang) utterance.lang = lang;
			synth.speak(utterance);
		}
	}, [speech]);
	if (!visible) return null;
	let adx = null;
	if (audio_url) {
		adx = <audio autoPlay><source src={audio_url} /></audio>
	}
	const rightClick = (evt) => {
		dispatch({ type: 'rename', context: 'toast', display: disp, name: data.name });
		evt.preventDefault();
	}
	if (disp === null) return adx;
	return <div onContextMenu={rightClick} onClick={() => setVisible(false)} className="toast" ><div className={"toast-body " + (data.cls ? data.cls : 'bg-warning')}>{disp}{adx}</div></div>
}