import React, { useRef, useState } from 'react';
export default function ({ data, isLoading, setPostVal }) {
	const ref = useRef(null);
	let { name, disabled, label, direct_post, val } = data;
	const [value, setValue] = useState(val ? true : false);
	//console.log(data);
	return <div className={"custom-control custom-switch"} >

		<input
			type="checkbox"
			className="custom-control-input"
			id={"id_" + name}
			disabled={disabled || isLoading}
			checked={value}
			onChange={(evt) => {
				let v = !value;
				setValue(v)
				setPostVal({ name: name, value: v, post: direct_post })
			}}
		/>
		<label className="custom-control-label" htmlFor={"id_" + name}>{label}</label>
	</div>
}
