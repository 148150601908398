import React, { useContext } from 'react';
import { StateContext } from "./Contexts";
export default function () {
    const state = useContext(StateContext);
    const { error } = state;
    return error ?
        <div style={{maxHeight:'500px',overflow:'auto'}} className={"row " + (state.edit!==null?'edit':'')+" alert alert-danger m-1 pre"}>
            <strong>ERROR: </strong> {error}
        </div>
        : "";
}