/*eslint no-unused-vars: "off"*/

import React, { useContext } from 'react';
import { StateContext, DispatchContext } from "./Contexts";
import Actionmenu from './Actionmenu'
import { useKey } from './Keyboard';
export default function () {
	const state = useContext(StateContext);
	const { header } = state.json
	const { settings, isLoading, ismobile, pathname } = state
	return (<div className="wrapper-header">
		<div className={"row " + (state.edit !== null ? 'edit' : '')}>
			{header.map(item => <HeaderLink key={item.name} ismobile={ismobile} pathname={pathname} item={item} />)}
		</div>
		<Actionmenu />
	</div>)
}
function HeaderLink({ item, pathname, ismobile }) {
	const dispatch = useContext(DispatchContext);
	const keycode = !ismobile && (!pathname || pathname.length < 2) && item.keycode ? item.keycode : false;
	const click = (evt) => {
		if (evt.ctrlKey) {
			var win = window.open('/' + item.name, '_blank');
			return true
		} else {
			dispatch({ type: 'pathname', pathname: '/' + item.name, title: item.disp });
		}

	}
	useKey(keycode, click)
	return <><button tabIndex={-1} key={item.id} onClick={click}
		className={'m-0 p-0 mx-1 px-1 btn btn-link'} >{item.disp}
		{keycode ? <div className="keycode">{keycode}</div> : ''}
	</button>
	</>
}