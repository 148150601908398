/*eslint no-unused-vars: "off"*/
import React, { useContext, useState, useEffect, useRef } from 'react';
import { StateContext, DispatchContext } from "./Contexts";
import { useTimer } from './functions'
import { useKey, useLayer } from "./Keyboard"
import { Select } from './Field'
export default function ({ datakey }) {
	const state = useContext(StateContext);
	const dispatch = useContext(DispatchContext);
	let data = state.json[datakey];
	const def = state.json[datakey.replace(/^data/, 'def')];
	useLayer('editmodal');
	useKey("escape", () => {
		dispatch({ type: 'pathname', pathname: state.pathname, search: '' });
	}, [], 'editmodal');

	/*
	const [def, setDef] = useState(null);
	useEffect(() => {
		setDef(state.json[datakey.replace(/^data/, 'def')])
	}, [state.json]);
*/
	if (data.length === 0) return null
	//if (def === null) return null
	data = data[0];
	//console.log(data);
	const columns = def.map((item) => <Field key={item.id} data={data[item.name]} def={item} />);
	if (state.isLoading) return null;
	return <div className="editmodal"><div className="container-sm"><div className="row">{columns}</div></div></div>
	//return <div className="editmodal"><div className="container"><div className="row"><div className="col-sm-2"></div><div className="col-sm-10"><b>{data.name}</b></div></div><div className="row">{columns}</div></div></div>
}

function Field({ def, data, handler }) {
	const state = useContext(StateContext);
	const dispatch = useContext(DispatchContext);
	const refInput = useRef(null);
	const { bearer } = state;
	//console.log(data);
	const postdata = async (v) => {
		var headers = {}
		if (bearer) headers['Authorization'] = 'Bearer ' + bearer;
		headers['Content-Type'] = 'application/json';
		var post = {}
		post[def.name] = v;
		//dispatch({ type: 'fetch' });
		const response = await fetch(state.base + state.pathname + state.search, {
			method: 'POST',
			//credentials: "include",
			mode: 'cors',
			cache: 'no-cache',
			headers,
			body: JSON.stringify(post),
		});
		const json = await response.json();
		if (json.data_error || json.data) {
			if (json.data_error) {
				var message = '';
				json.data_error.forEach((row) => { message += row.message + "\n" })
				message = message.replace(/(,[a-z])/gi, "\n\t\t$1");
				dispatch({ type: 'error', message });
				if (json.toasts) dispatch({ type: 'toasts', toasts: json.toasts });
			} else {
				dispatch({ type: 'json', json });
			}
		}
	}
	const [val, setVal] = useTimer(data, postdata, 1000);
	const rightClick = (evt) => {
		dispatch({ type: 'rename', context: def.tbl, display: def.disp, name: def.name, fid: def.fid });
		evt.preventDefault();
	}

	const rightClickInput = (evt) => {
		dispatch({ type: 'stored_procedure', name: 'Search Edit history', values: { Search: def.name, Go: "Zoek" } });
		evt.preventDefault();
	}

	if (def.is_id) return null
	//if (['name', 'card_id'].includes(def.name)) return null
	return (
		<div className="col-sm-12">
			<div className="row" title={def.disp}>
				<div className={"col-sm-2 p-1 text-truncate"} onContextMenu={rightClick}>
					{def.disp}
				</div>
				<div className="col-sm-10 p-1">
					{def.elem === 'Select' ? <Select def={def} data={data} value={val} setValue={setVal} updateValue={setVal} refInput={refInput} />
						:
						handler ? data : def.att === 'nvarchar(max)' ?
							<textarea onContextMenu={rightClickInput} placeholder={def.disp} readOnly={!def.is_upd} onChange={(e) => setVal(e.target.value)} className="p-1 form-control" value={val ? val : ''} rows={val && val.length > 40 ? 2 : 1} />
							:
							<input onContextMenu={rightClickInput} placeholder={def.disp} readOnly={!def.is_upd} onChange={(e) => setVal(e.target.value)}
								className="p-1 form-control" value={val !== null ? val : ''}
								type={def.att === 'int' ? 'number' : 'text'}
								autoComplete="off"
							/>
					}
				</div>
			</div>
		</div>
	);
}