import React, { useState, useEffect, useContext } from 'react';

export default function ({ def, onFocus, data, disabled, value, setValue, updateValue, refInput, dispatch, setEditUpdatedfield }) {
	const [hasFocus, setHasFocus] = useState(false);

	const [editorRef, setEditorRef] = useState(false);

	//const monacoRef = useRef();
	let options = [];
	let objects = [];
	let lastList = '';
	let timeout;
	//let EditorRef;
	const monaco = window.monaco;
	const tsqlapp = window.TSQLAPP;
	//console.log(tsqlapp);
	try {
		options = JSON.parse(def.extra);
	} catch (e) {

	}
	if (typeof options !== 'object' || !options) options = { language: "tsql.app", theme: "vs", insertSpaces: false, automaticLayout: true }
	useEffect(() => {
		if (editorRef) {
			if (value !== editorRef.getValue()) editorRef.setValue(value);
		}
	}, [value]);
	options.value = value;
	let minHeight = 38;
	//console.log(def);
	if (def.rows ?? 0 > 0) minHeight = (19 * def.rows)
	//console.log(minHeight);
	/*	if (editorValue !== value) {
			console.log(editorValue, value,data);
			setEditorValue(value);
		}
		*/
	/*
	useEffect(() => {
		console.log('set',EditorRef);
		if(EditorRef) EditorRef.setValue(value);
	},[value]);
	*/
	//	console.log(options);
	useEffect(() => {
		//console.log('set');
		let fields = [];

		if (objects.length === 0) {
			const after = (json) => {
				if (Array.isArray(json)) {
					//objects = json;
					objects = json.map(item => { return { ...item, obj: item.obj.toLowerCase() } })
				}
			}
			//console.log('loading objects')
			dispatch({ type: 'post', pathname: '/v0/objects', method: 'GET', after })

		}
		if (options.language === 'tsql.app' && monaco.languages.getLanguages().find(e => e.id === 'tsql.app') === undefined) {
			monaco.languages.register({ id: "tsql.app" });
			monaco.languages.setMonarchTokensProvider("tsql.app", tsqlapp);
			/*monaco.languages.setLanguageConfiguration("tsql.app", {
				wordPattern: /(-?\d*\.\d\w*)|([^\`\~\!\#\%\^\&\*\(\)\-\=\+\{\}\\\|\;\:\'\"\,\.\<\>\/\?\s]+)/g,
			})*/
			monaco.languages.registerCompletionItemProvider("tsql.app", {
				triggerCharacters: ['@']
				, provideCompletionItems: (model, position, p, q, r) => {
					let suggestions = [];
					let txt = document.getElementsByName('pre_declare')[0].value;
					txt += ' ' + model.getValue()
					let matches = [...new Set(txt.match(/[@][a-z0-9_@]+/gi))];
					matches.forEach((obj) => {
						suggestions.push(
							{
								label: obj,
								filterText: obj.substring(1),
								kind: monaco.languages.CompletionItemKind.Variable,
								insertText: obj.substring(1),
								//sortText: w.toLowerCase(),
							}
						)
					});
					return { suggestions: suggestions };
				},
			});
			monaco.languages.registerCompletionItemProvider("tsql.app", {
				triggerCharacters: ['.', ',']
				, provideCompletionItems: (model, pos, p, q, r) => {
					//console.log(pos, p, q, model.getWordAtPosition(pos), model.getLineDecorations(pos.LineNumber));
					/*       model.getLineDecorations(pos.LineNumber).forEach((dec) => {
							 console.log(model.getValueInRange(dec.range));
						   });
						   console.log();
						   */
					let suggestions = [];
					let x = 0
					/*
					tsqlapp.keywords.forEach((w) => {
						suggestions.push({
							label: w,
							filterText: w.toLowerCase(),
							kind: monaco.languages.CompletionItemKind.Keyword,
							insertText: w,
							//sortText: w.toLowerCase(),
						})
						x += 1;
					})
					objects.forEach((obj) => { suggestions.push({ ...obj }) });
					*/
					//console.log("fields", fields)
					fields.forEach((obj) => { suggestions.push({ ...obj }) });
					return { suggestions: suggestions };
				},
			});
			monaco.languages.registerCompletionItemProvider("tsql.app", {
				provideCompletionItems: (model, pos, p, q, r) => {
					//console.log(pos, p, q, model.getWordAtPosition(pos), model.getLineDecorations(pos.LineNumber));
					/*       model.getLineDecorations(pos.LineNumber).forEach((dec) => {
							 console.log(model.getValueInRange(dec.range));
						   });
						   console.log();
						   */
					let suggestions = [];
					let x = 0
					tsqlapp.keywords.forEach((w) => {
						suggestions.push({
							label: w,
							filterText: w.toLowerCase(),
							kind: monaco.languages.CompletionItemKind.Keyword,
							insertText: w,
							//sortText: w.toLowerCase(),
						})
						x += 1;
					})
					objects.forEach((obj) => { suggestions.push({ ...obj }) });
					//console.log("fields", fields)
					//fields.forEach((obj) => { suggestions.push({ ...obj }) });
					return { suggestions: suggestions };
				},
			});
		}


		let editor = monaco.editor.create(refInput.current, options);
		setEditorRef(editor);
		//EditorRef = editor;
		editor.addAction({
			id: 'save', label: 'Save', keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS, monaco.KeyCode.Insert, monaco.KeyCode.F5]
			, run: () => {
				let val = editor.getValue()
				//setEditorValue(val);
				dispatch({ type: 'save', key: def.name, val: val, save: true });
				//console.log('SAVE')
			}
		});
		/*	editor.addAction({
				id: 'exec', label: 'Execute', keybindings: [monaco.KeyCode.F5]
				, run: () => {
					//dispatch({ type: 'save', key: def.name, val: editor.getValue(), save: true });
					console.log(editor.getSelection().getPosition());
				}
			});
			*/
		const a1precondition = () => { console.log('werwer'); return true }
		editor.addAction({
			id: 'exit', label: 'Exit', keybindings: [monaco.KeyCode.Escape]
			, precondition: 'false'
			, run: () => {
				//const contextMenuVisible = editor.getActionContext().ContextMenuVisible;
				//if (contextMenuVisible) {
				//	editor.trigger('hideContextMenu', 'editor.action.hideContextMenu');
				//} else {
				document.activeElement.blur();
				//}
			}
		});
		let ignoreEvent = false;
		editor.onDidContentSizeChange(() => {
			if (ignoreEvent || def.rows) return;
			try {
				const contentHeight = window.innerHeight - 180;
				ignoreEvent = true;
				refInput.current.parentElement.scrollIntoView({ behavior: "instant", block: "start" });
				refInput.current.style.height = contentHeight + 'px';
				editor.layout({ width: refInput.current.offsetWidth, height: contentHeight });
			} finally {
				ignoreEvent = false;
			}
			//editor.getContentHeight()
		});
		editor.onDidChangeModelContent((a) => {
			//console.log(objects);
			if (objects.length) {
				let code = editor.getValue().toLowerCase();
				let matches = code.match(/(\[[^\]]+\]|[a-z0-9_]+)/gi)
				//console.log(matches,objects);
				let result = [];
				if (matches) result = objects.filter(item => (matches.indexOf(item.obj) !== -1 || matches.indexOf("[" + item.obj + "]") !== -1));
				let ids = result.map((obj) => { return obj.object_id })
				let list = ids.join(',');
				if (list !== lastList) {
					if (timeout) clearTimeout(timeout);
					timeout = setTimeout(() => {
						lastList = list;
						const after = (json) => {
							if (Array.isArray(json)) {
								fields = json;
							}
						}
						//console.log('loading fields')
						dispatch({ type: 'post', pathname: '/v0/fields', method: 'POST', data: ids, after })
					}, 400);
					//console.log(lastList);
				}
			}
		})
		editor.onDidBlurEditorText(() => {
			//console.log('onDidBlurEditorText', !disabled);
			setEditUpdatedfield(false); setHasFocus(false);
			if (!disabled) updateValue(editor.getValue());
		})
		return () => {
			editor.dispose();
		}
	}, [])
	return (<div ref={refInput} style={{
		width: '100%',
		height: minHeight, //gb240212
		//minHeight: minHeight + 'px'
	}} />);
}