import React, { useContext, useState, useEffect } from 'react';
import { StateContext } from "./Contexts";
export default function () {
	const state = useContext(StateContext);
	const { isLoading, settings } = state;
	const [shouldRender, setRender] = useState(isLoading);
	const { loadingScreenSeconds } = settings;
	useEffect(() => {
		if (isLoading) setRender(true);
	}, [isLoading]);

	const onAnimationEnd = () => {
		if (!isLoading) setRender(false);
	};
	return shouldRender ?
		<div
			style={isLoading ? {} : { animation: "fadeout 0.1s" }}
			onAnimationEnd={onAnimationEnd}>
			<div className="agfxloading" style={loadingScreenSeconds ? { animation: "fadein " + loadingScreenSeconds + "s" } : {}}>
				<div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
			</div>
		</div>
		: '';
}