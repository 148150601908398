import React, { useContext, useCallback, useEffect } from 'react';
import { StateContext, DispatchContext } from "./Contexts";
import { FaFilePdf, FaFile, FaFileArchive, FaTrashAlt, FaDownload } from "react-icons/fa";
import { formatDateTime } from './format'
import { useDropzone } from 'react-dropzone'

export default function () {
	const state = useContext(StateContext);
	const dispatch = useContext(DispatchContext);
	const { files } = state.json

	if (files === undefined) return null
	useEffect(() => {
		const pasteFunction = (evt) => {
			var items = evt.clipboardData.items;
			for (var i = 0; i < items.length; i++) {
				// Skip content if not image
				if (items[i].type.indexOf("image") === -1) continue;
				let file = items[i].getAsFile();

				let reader = new FileReader();
				reader.onload = function (e) {
					let filedate = (file.lastModifiedDate ?? (new Date())).toISOString() //iOS werkt niet met lastModifiedDate
					let values = {}
					values['@name'] = file.name
					values['@mimetype'] = file.type
					values['@modified'] = filedate
					values['@filesize'] = file.size
					values['@base64data'] = e.target.result.split(';base64,', 2)[1]
					dispatch({ type: 'stored_procedure', name: 'sys_file_upload', values })
				}
				reader.readAsDataURL(file);
			}
		}
		window.addEventListener("paste", pasteFunction, true);
		return () => {
			window.removeEventListener("paste", pasteFunction, true);
		}
	});
	const downloadFile = (file) => {
		if (state.settings.api_version > "220615") {
			var link = document.createElement("a");
			link.setAttribute('download', '');
			link.href = file.link;
			document.body.appendChild(link);
			link.click();
			link.remove();
		} else {
			let values = {}
			values['@file_id'] = file.file_id
			dispatch({ type: 'stored_procedure', name: 'sys_file_download', values })
		}
	}
	const deleteFile = (file) => {
		let values = {}
		values['@file_id'] = file.file_id
		dispatch({ type: 'stored_procedure', name: 'sys_file_delete', values })
	}
	const previewFile = (file) => {
		let values = {}
		values['@file_id'] = file.file_id
		dispatch({ type: 'stored_procedure', name: 'sys_file_preview', values })
	}

	let filelist = files.map((file, i) => {
		return <File key={i} file={file} downloadFile={downloadFile} deleteFile={deleteFile} previewFile={previewFile} />

	})
	const uploadFiles = (files) => {
		if (files.length === 0) return
		if (state.isLoading) {
			setTimeout(() => uploadFiles(files), 500);
			return
		}
		if (state.settings.api_version > "220615") {
			// NEW METHOD:

			let api_multipart_form = new FormData()
			let values = {}
			values['@modified'] = []
			files.forEach(file => {
				if (file.size < (state.settings.filesize_limit ?? 2147483648)) {
					let filedate = (file.lastModifiedDate ?? (new Date())).toISOString() //iOS werkt niet met lastModifiedDate
					api_multipart_form.append('files', file)
					values['@modified'].push({ filename: file.name, modified: filedate });
				} else {
					dispatch({ type: 'toasts', toasts: [{ id: 1, data: "{\"name\":\"file_size\",\"cls\":\"bg-danger text-white\",\"disp\":\"" + file.name + ": File too big\",\"secs\":5}" }] });
				}

			});
			//api_multipart_form.append('file', file)
			values.api_multipart_form = api_multipart_form;
			//values['@name'] = file.name
			//values['@mimetype'] = file.type
			//values['@filesize'] = file.size
			dispatch({ type: 'stored_procedure', name: 'sys_file_upload', values });
			//if (files.length) setTimeout(() => uploadFiles(files), 500);
		} else {
			let file = files.pop();
			if (file.size < (state.settings.filesize_limit ?? 2147483648)) {
				// OLD METHOD:
				let reader = new FileReader();
				reader.onload = function (e) {
					let filedate = (file.lastModifiedDate ?? (new Date())).toISOString() //iOS werkt niet met lastModifiedDate
					let values = {}
					values['@name'] = file.name
					values['@mimetype'] = file.type
					values['@modified'] = filedate
					values['@filesize'] = file.size
					values['@base64data'] = e.target.result.split(';base64,', 2)[1]
					dispatch({ type: 'stored_procedure', name: 'sys_file_upload', values })
					if (files.length) setTimeout(() => uploadFiles(files), 1000);
				}
				reader.readAsDataURL(file);

			} else {
				dispatch({ type: 'toasts', toasts: [{ id: 1, data: "{\"name\":\"file_size\",\"cls\":\"bg-danger text-white\",\"disp\":\"" + file.name + ": File too big\",\"secs\":5}" }] });
				if (files.length) setTimeout(() => uploadFiles(files), 1000);
			}
		}
	}

	const onDrop = useCallback(acceptedFiles => {
		uploadFiles(acceptedFiles);
	}, [])


	let accept = state.settings.allow_file_types ?? ''
	//accept = accept.toLowerCase()
	if (accept === '*' || accept === '*.*') accept = ''

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept })

	//console.log(files);
	return (
		<>
			<div className="p-1 col-sm-12">
				<div className="rounded img-thumbnail" {...getRootProps()}>
					<input {...getInputProps()} />
					{
						isDragActive ?
							<p>Drop the file(s) ...</p> :
							<p>Drag file(s) here, or click to select file(s). Use Ctrl+V to paste screenshots or images from clipboard.</p>
					}
					{accept ? <p>Accepted files: {accept} </p> : ''}
				</div>
			</div>
			{filelist}
		</>
	)
}

function File({ file, downloadFile, deleteFile, previewFile }) {
	//console.log(file);
	let { filesize, mimetype, name, description, modified, uploaded, user_name } = file ?? {}
	let thumbnail = <FaFile color="#c00" size="4em" />
	if (mimetype === 'application/pdf') thumbnail = <FaFilePdf color="#f80" size="4em" />
	return (
		<div className="p-1 col-sm-2">
			<div className="m-2 opacity-50-hover float-left" onClick={() => deleteFile(file)}><FaTrashAlt size="1.5em" color="#f00" /></div>
			<div className="m-2 opacity-50-hover float-right" onClick={() => downloadFile(file)}><FaDownload size="1.5em" color="#00f" /></div>
			<div className="text-decoration-none btn-link pointer" onClick={() => previewFile(file)} title={name}>

				<div className="rounded img-thumbnail">
					<div className="text-center">
						{thumbnail}
					</div>
					<div className="text-truncate text-center">
						<b>{name}</b>
					</div>
					<small className="text-muted text-left text-break">
						<b>Description:</b>
						<br />
						{description}
						<br />
						<b>Size:</b> {Math.round((filesize / 1024) + 0.5)} KB
						<br />
						<b>Modified:</b> {formatDateTime(modified)}
						<br />
						<b>Uploaded:</b> {formatDateTime(uploaded)}
						<br />
						<b>By:</b> {user_name}
					</small>
					{/*
                <img src={src}
                    alt="nxn"
                    className="img-fluid img-thumbnail" />
                    */}
				</div>
			</div>
		</div>
	)
}