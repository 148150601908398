/*eslint no-unused-vars: "off"*/
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { StateContext, DispatchContext } from "./Contexts";
import { globals } from './globals';
import { useStateDelay } from './functions'
import { formatDate, formatFloat } from './format';
import Table from "./elements/Table";
//import Field from './Field'
export default function (props) {
	const dispatch = useContext(DispatchContext);
	//const [def, setDef] = useState(props.def);
	const { isLoading, settings } = props;
	const [def, setDef] = useState(props.def);
	const [data, setData] = useState(props.data);
	//const [reload, reloadHandler] = useState(false);
	const [handlerId, setHandlerId] = useState(globals.selectedId);
	//console.log("tsql_handler_id",data)
	//const [realHandlerId, setRealHandlerId] = useState(settings.handler_id ? parseInt(settings.handler_id) : 0);
	const [delayedId, setDelayedId] = useStateDelay(globals.selectedId, settings.handler_delay ? parseInt(settings.handler_delay) : 400);
	let handlerdata = (data && data[0]) ?? null;
	//console.log('d', settings.cardname, def);
	//if(globals.selectedId === 0) return null
	useEffect(() => {
		globals.setHandlerId = setHandlerId;
		//globals.reloadHandler = reloadHandler;
		return () => {
			globals.setHandlerId = false; //globals.reloadHandler = false;
		}
	}, []);
	//					dispatch({ type: 'post', pathname: state.pathname + '/-1', search: '?edit=' + def.name, data: { "list_order": (moveto * 2) + (moveto > def.id ? 1 : -1) }, after })
	useEffect(() => {
		setData(props.data);
		setDef(props.def);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.def]) //gb221026 moeilijk te snappen
	/*	useEffect(() => {
			let n = parseInt(settings.handler_id);
			if (n !== realHandlerId) setRealHandlerId(n);
			console.log('handler2', realHandlerId, n);
			//if (delayedId !== handlerId) setDelayedId(handlerId);
		}, [settings.handler_id])
	*/
	useEffect(() => {
		//console.log('handler2',handlerId,delayedId,globals.selectedId);
		if (delayedId !== handlerId) setDelayedId(handlerId);
	}, [handlerId, delayedId])
	useEffect(() => {
		//console.log('dispatch handler ', delayedId);
		//console.log('dispatch handler ', delayedId)
		//if (isLoading) return
		if (delayedId > 0 && delayedId === globals.selectedId) {
			//if (!reload)
			if (data && data[0] && data[0]["tsql_handler_id"] === delayedId) return
			const after = (json) => {
				//console.log('dispatch handler ', delayedId, globals.selectedId);
				let { data_handler } = data;
				if (!json.data_handler) data_handler = []
				//if (delayedId !== globals.selectedId) setDelayedId(globals.selectedId);
				if (delayedId === globals.selectedId) {
					setData(json.data_handler);
					//delete json.data_handler
					//json["merge"] = true;
					//dispatch({ type: 'json', json: json }) GB231114 dit fucked de boel op!
				} else {
					setDelayedId(globals.selectedId);
				}
			}
			dispatch({ type: 'post',method: 'GET' //, method: 'POST', sendSelectedIds: true
			, search: '?handler=' + delayedId, after })
			//if(reload) reloadHandler(false);
		}
	}, [delayedId]) //,reload
	const columns = useMemo(() => {
		return def.map((item) => <Field key={item.id} data={handlerdata && (handlerdata[item.name] ?? null) /* 200914 !== undefined ? handlerdata[item.name] : null*/} def={item} dispatch={dispatch} />);
	}, [def, data]);
	return (<div className="wrapper-footer">
		<div className="row bg-secondary text-white">{columns}</div>
	</div>);
}
function Field(props) {
	const { def } = props;
	if (!def.elem) return <Text {...props} />
	if (def.elem === 'Table') return (
		<Outerbig>
			<Inner {...props} delta={2}>
				<Table data={props.data} def={def} /> {/*221128*/}
			</Inner>
		</Outerbig>
	)
	return 'Unknown: ' + def.elem;
}
function Text(props) {
	const { data } = props;
	return (
		<Outer {...props}>
			<Inner {...props}>
				{data ?? '-'}
			</Inner>
		</Outer>
	);

}
function Outerbig({ children }) {
	return (
		<div className={"col-sm-12"}>
			<div className={"row"}>
				{children}
			</div>
		</div>
	);
}
function Outer({ children, def }) {
	let size = 6
	if (def.att === 'nvarchar(max)') size = 12
	return (
		<div className={"col-sm-" + size + ""}>
			<div className={"row"}>
				{children}
			</div>
		</div>
	);
}
function Inner({ children, def, dispatch, delta }) {
	let truncate = " text-truncate"
	let display = children;
	if (def.att === 'nvarchar(max)') {
		truncate = ""
		if (!delta) delta = 2
	}
	if (def.att === 'float' && display !== '-') display = formatFloat(display);

	if (!delta) delta = 4
	const rightClick = (evt) => {
		dispatch({ type: 'rename', /* context: def.tbl,*/ display: def.disp, name: def.name, fid: def.fid, cls: def.cls });
		evt.preventDefault();
	}
	return (
		<>
			<div className={"col-sm-" + delta + " text-truncate" + (def.cls ? ' ' + def.cls : '')} onContextMenu={rightClick}>{def.disp}</div>
			<div className={"col-sm-" + (12 - delta) + truncate + (def.cls ? ' ' + def.cls : '')}><i title={display.toString()}>{display}</i></div>
		</>
	);
}