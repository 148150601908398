import React from 'react';

export default function ({ children }) {
    return (
        <div className="row">
            <div className="col text-center py-3">
                <h1 className="h1 font-weight-light text-primary">{children}</h1>
            </div>
        </div>
    );
}
