import React, { useContext, useState } from 'react';
import Title from "../Title";
//import { globals } from '../globals';

import { useKey } from '../Keyboard'
//import { UserContext } from '../services/UserContext'
import { Input, Button, Errors } from './Forms'
import { uuidv4, getCookie, setCookie, isEmail } from '../functions'
export default function ({ history }) {
	//const user = useContext(UserContext);
	const [username, setUsername] = useState("");
	const [alldone, setAlldone] = useState(false);
	/*
	const [password, setPassword] = useState("");
	var hash = window.location.hash;
	if(hash === "#activated"){
		window.history.replaceState(window.history.state, document.title, window.location.href.split('#')[0]);
		user.success = "Account succesvol geactiveerd, u kunt nu inloggen";
	}
	*/
	var hash = window.location.hash;
	console.log(hash);

	const onClick = (e) => {

		//let regex = new RegExp('(?:(?:^|.*;\\s*)' + "domains_api_base" + '\\s*\\=\\s*([^;]*).*$)|^.*$');
		let api_base = getCookie("domains_api_base");
		if (api_base) {
			let url = api_base + '/v1/tsqlsystem/ticket'
			let client_seed = uuidv4()
			fetch(url, {
				method: 'POST',
				body: JSON.stringify({ email: username, seed: client_seed, path: window.location.href })
			},)
				.then(res => res.json())
				.then(json => {
					setAlldone(true);
					let { email } = json;
					setCookie("domains_api_ticket_email", email, 88400);
					setCookie("domains_api_ticket_client_seed", client_seed, 88400);
					setCookie("domains_api_ticket_server_seed", "", 0);
					//console.log(json, token, email)
				})
				.catch(res => {
					console.log('err', res);
				});
		}
		//user.login(username, password, history, '/account/user'); 
		e.preventDefault();
	}
	let showButton = !isEmail(username);
	useKey('Enter', onClick);
	return (
		<section>
			<Title>{alldone ? "Wait for" : "Get"} E-mail Ticket</Title>

			<div className="mx-auto col-lg-4 col-md-6 mb-4">
				{alldone ?
					<div>An email has been sent to {username} , please wait for it and log in using the link in the email.</div>
					: <form>
						<Input
							type="email"
							link={{ to: '/account/login', text: 'Terug naar login' }}
							onChange={e => setUsername(e.target.value)}
							name="username"
							placeholder="E-mailadres" icon="envelope"
						/>
						<Button
							disabled={showButton}
							onClick={onClick}>Get Ticket</Button>
					</form>}
			</div>
		</section>
	);
}
