import React from 'react';
//import { StateContext } from "./Contexts";
export default function ({ message }) {
    //const state = useContext(StateContext);
    //const { error } = state;
    return message ?
        <div className={"isammodal2"}>
            <div className={"row alert alert-danger m-1 pre col-sm"}>
                <strong>Warning: </strong> {message}
            </div>
        </div>
        : "";
}