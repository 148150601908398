import React, { useRef, useEffect, useState } from 'react';
export default function ({ data, isLoading, setPostVal }) {
	const ref = useRef(null);
	let { html, name, iframe, className, iframe_message_data, type } = data;
	let [message_data, setMessage_data] = useState(iframe_message_data);
	useEffect(() => {
		const mes = (event) => {
			let this_frame_window = ref.current.querySelectorAll('iframe')[0].contentWindow;
			if (event.source !== this_frame_window) return;
			let { data } = event;
			let { get_iframe_message_data } = data;
			if (get_iframe_message_data) {
				this_frame_window.postMessage(iframe_message_data);
			} else {
				if (type === 'chart') {
					setPostVal({ name: name + "_dataset", value: data.dataset ?? null })
					setPostVal({ name: name + "_number", value: data.number ?? null })
					setPostVal({ name: name, value: data.label ?? null, post: true })

				} else {
					setPostVal({ name: name, value: data, post: true });
				}
				event.preventDefault();
				event.stopPropagation();
			}
		}
		if (iframe) window.addEventListener('message', mes, false);

		return () => {
			if (iframe) window.removeEventListener('message', mes, false);
		}

	}, [setPostVal, iframe_message_data]);
	useEffect(() => {
		if (message_data !== iframe_message_data) {
			ref.current.querySelectorAll('iframe')[0].contentWindow.postMessage(iframe_message_data);
			setMessage_data(iframe_message_data);
		}
	}, [iframe_message_data]);
	return <div ref={ref} /*disabled={isLoading} */ className={className} dangerouslySetInnerHTML={{ __html: html }} />
}



