import React, { useState, useEffect } from 'react';
export default function ({ value, className, def, extra, refInput, mask, maxLength, updateValue, valid, setValue, disabled, type, placeholder, onFocus }) {
	const [realval, setRealval] = useState(null);
	const [val, setVal] = useState('');
	const [hasFocus, setHasFocus] = useState(false);
	useEffect(() => {
		setRealval(value);
	}, [value])
	useEffect(() => {
		let newvalue = realval;
		if (hasFocus) {
			newvalue = newvalue !== null ? newvalue.toString().replace(/[.]/gi, ',') : '';
			if (newvalue === val) return;
		} else {
			newvalue = newvalue !== null ? newvalue.toString() : '0';
			try {
				const { locale, currency } = extra ?? {};
				newvalue = new Intl.NumberFormat(locale ?? 'nl', { style: 'currency', currency: currency ?? 'EUR' }).format(newvalue);
				if (realval === null) newvalue += ' *';
			} catch (e) {
				newvalue = newvalue + ' (' + e.message + ')';
			}
		}

		setVal(newvalue);
	}, [realval, hasFocus, def])
	const onChange = (evt) => {
		let newvalue = evt.target.value;
		newvalue = newvalue.replace(/[^0-9,.]/gi, '');
		newvalue = newvalue.replace(/[,.]([0-9]*)$/gi, 'p$1');
		newvalue = newvalue.replace(/[,.]+/gi, '');
		newvalue = newvalue.replace(/[p]+/gi, '.');
		setRealval(newvalue);
		setValue(newvalue);
	}
	return <input
		disabled={disabled}
		mask={mask}
		ref={refInput}
		type={type}
		readOnly={!def.is_upd}
		tabIndex={def.is_upd ? "1" : "-1"}
		maxLength={maxLength}
		autoComplete="off"
		placeholder={placeholder}
		title={placeholder}
		onFocus={(evt) => { setHasFocus(true); onFocus({ evt, refInput }); }}
		onBlur={(evt) => { setHasFocus(false); if (!disabled && valid) { updateValue(evt.target.value) } }}
		className={className} value={val} onChange={onChange}
	/>
}