import React, { useContext } from 'react';
import { StateContext, DispatchContext } from "./Contexts";
import { useKey } from './Keyboard'

export default function () {
    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);
    const data = state.json.breadcrumbs ?? [];
    const breadcrumbs = data.map((item, key) => {
        //console.log(item);
        return <Breadcrumb item={item} key={key} dispatch={dispatch} />
        //return <li key={key} className="breadcrumb-item"><a href="#">{card_name}</a></li>
    })
    if (data.length) document.title = data[data.length - 1].disp;
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb py-1 px-3 mb-1">
                <Breadcrumb item={{ card_name: 'Home', pathname: '/', search: '' }} dispatch={dispatch} />
                {breadcrumbs}
            </ol>
        </nav>
    );
}
function Breadcrumb({ item, dispatch }) {
    const { card_name, pathname, search, disp, is_pl, context } = item;
    let ctx = context ? context : (is_pl ? 'breadcrumb_picklist' : 'breadcrumb')
    const rightClick = (evt) => {
        if (disp) dispatch({ type: 'rename', context: ctx, display: disp, name: card_name });
        evt.preventDefault();
    }
    const click = (evt) => {
        let pn = pathname
        let se = search
        if (!pn) {
            pn = window.location.pathname
            se = window.location.search
        }
        if (se === null) se = ''
        if (evt.ctrlKey) {
            var win = window.open(pn + se, '_blank');
            return true
        } else {
            dispatch({ type: 'pathname', pathname: pn, search: se/*, title: item.disp*/ });
        }
    }
    //{keycode ? <div className="keycode">{keycode}</div> : ''}
    return (
        <li className="breadcrumb-item">
            <button tabIndex={-1} onClick={click} onContextMenu={rightClick}
                className={'m-0 p-0 border-0 align-top btn btn-link'} >{disp ? disp : card_name}</button>
        </li>
    );
}