import React from 'react';
export default function ({ url, base64data }) {

	return <div>
		<iframe src={"data:application/pdf;base64," + base64data} height="100%" width="100%"></iframe>
	</div>
}



