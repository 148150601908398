import { useState, useEffect, useCallback } from 'react';
export function useTimer(initval, initfunc, millis = 1000) {
	const didMount = useDidMount();
	const [val, setVal] = useState(initval);
	const initfunc_cb = useCallback(initfunc, [])
	// const didMount = useCallback(useDidMount,[])
	useEffect(() => {
		if (didMount) {
			let timer = setTimeout(() => {
				//console.log('setTimeout');
				initfunc_cb(val);
			}, millis);
			return () => {
				//console.log('clearTimeout');
				clearTimeout(timer)
			}
		}
	}, [val, millis, initfunc_cb]);// eslint-disable-line react-hooks/exhaustive-deps
	//}, [val,didMount,millis,initfunc]);
	return [val, setVal]
}
export function useDidMount() {
	const [didMount, setDidMount] = useState(false)
	useEffect(() => setDidMount(true), [])
	return didMount;
}
export function escapeRegExp(string) {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function useStateDelay(initval, millis = 1000) {
	const [val, setVal] = useState(initval);
	const [delayVal, setDelayVal] = useState(initval);
	let timer = false
	useEffect(() => {
		//if (delayVal === val) return;
		if (timer) clearTimeout(timer);
		//console.log('setTimeout')
		timer = setTimeout(() => {
			setVal(delayVal);
		}, millis);
		return () => {
			//console.log('clearTimeout')
			if (timer) clearTimeout(timer);
		}
	}, [delayVal]);
	return [val, setDelayVal]
}
export function getCoords(elem) {
	let box = elem.getBoundingClientRect();
	return {
		top: box.top + window.pageYOffset,
		bottom: box.bottom + window.pageYOffset,
		left: box.left + window.pageXOffset,
		right: box.right + window.pageXOffset,
	};
}
export function uuidv4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		//(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) //GB231121
		(((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> c / 4).toString(16)
	);
}


export function getCookie(name) {
	var regex = new RegExp('(?:(?:^|.*;\\s*)' + name + '\\s*\\=\\s*([^;]*).*$)|^.*$');
	return document.cookie.replace(regex, "$1");
}
export function setCookie(name, value, maxAgeInSeconds = 86400) {
	document.cookie = name + "=" + value + ";domain=" + window.location.hostname + ";path=/;max-age=" + maxAgeInSeconds.toString();
}

export function isEmail(email) {
	let re = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+.(?:\\.[a-zA-Z0-9-]+)+$', 'g');
	return email.match(re) ? true : false;
}


export function prettifyXml(sourceXml) {
	var xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
	var xsltDoc = new DOMParser().parseFromString([
		// describes how we want to modify the XML - indent everything
		'<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
		'  <xsl:strip-space elements="*"/>',
		'  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
		'    <xsl:value-of select="normalize-space(.)"/>',
		'  </xsl:template>',
		'  <xsl:template match="node()|@*">',
		'    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
		'  </xsl:template>',
		'  <xsl:output indent="yes"/>',
		'</xsl:stylesheet>',
	].join('\n'), 'application/xml');

	var xsltProcessor = new XSLTProcessor();
	xsltProcessor.importStylesheet(xsltDoc);
	var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
	var resultXml = new XMLSerializer().serializeToString(resultDoc);
	return resultXml;
};

//console.log(prettifyXml('<root><node/></root>'));