import React, { useContext, useState, useEffect } from 'react';
import { StateContext, DispatchContext } from "./Contexts";
import { useKey, useLayer } from "./Keyboard"
export default function () {
	const state = useContext(StateContext);
	const dispatch = useContext(DispatchContext);
	const { rename, settings } = state;
	const [display, setDisplay] = useState('');
	useEffect(() => {
		if (rename.display) setDisplay(rename.display);
	}, [rename]);
	const cancel = (evt) => {
		dispatch({ type: 'rename' });
		evt.preventDefault();
	}
	let lang = rename.lang ?? settings.lang
	useLayer('rename');
	useKey("escape", cancel, [], 'rename');
	useKey("enter", () => {
		//dispatch({type:"post",data:{rename:"gewewrg"}});
		dispatch({
			type: "post",
			after: (json) => dispatch({ type: "json", json }),
			data: { api_action_post: { ...rename, display, lang: lang } }
		});
	}, [], 'rename')
	//if (!rename) return null
	return (
		<div className={'pickmodal'} onContextMenu={cancel} onMouseDown={cancel}>
			<div onMouseDown={e => e.stopPropagation()} onContextMenu={e => e.stopPropagation()}>
				<div className="form-group">
					<label htmlFor="display">Display for <b>{rename.context ? rename.context : '*'}.{rename.name}</b> with lang <b>{lang}</b>
						{rename.fid ? ' (?focus=' + rename.fid + ') ' : ''}
						<button onClick={(evt) => {
							cancel(evt);
							dispatch({ type: 'pathname', pathname: '/api_global_fields', search: "?filter=name.swhas(+" + rename.name.replace(/_id$/i,"") + ")" })
						}} type="button" className="btn align-baseline btn-link my-0 py-0">translations</button>
					</label>
					<input
						maxLength="255"
						autoFocus
						autoComplete="off"
						type="text" onChange={(evt) => setDisplay(evt.target.value)} value={display} className="form-control" id="display" />
					{rename.cls ?
						<>
							<label class="mt-3" htmlFor="cls">Class defined in Listclass, edit mode (?edit={rename.name}):</label>
							<input
								readOnly
								type="text" value={rename.cls} className="form-control" id="cls" />
						</>
						: ''}
				</div>
			</div>
		</div>
	);
}