import React, { useState, useEffect, useRef, useContext } from 'react';
import { DispatchContext } from "./Contexts";
import { triggerKey } from "./Keyboard";
import { globals } from './globals';
export default function ({ state, data }) {
	const { settings, isLoading, popup, pathname } = state;

	//let expiresInSeconds = expiresTime - Math.round((new Date()).getTime() / 1000) - Math.round(Math.random() * 60)
	//const [timestamp, setTimestamp] = useState(globals.selectedId);
	const [statusId, setStatusId] = useState(globals.selectedId);
	const [docHasFocus, setDocHasFocus] = useState(document.hasFocus());
	const dispatch = useContext(DispatchContext);
	const ref = useRef();
	useEffect(() => {
		globals.setStatusId = setStatusId;
		return () => { globals.setStatusId = false }
	}, []);

	useEffect(() => {
		const hasFocus = (evt) => {
			//if (popup && popup.focus) popup.focus();
			if (ref.current.getAttribute("data-focus") !== document.hasFocus().toString()) {
				setDocHasFocus(document.hasFocus());
				//console.log(refreshCountDown)
				//console.log('foc', document.hasFocus())
				//if (document.hasFocus() && !state.json.data_modalview && settings.is_list === "1") dispatch({ type: 'refresh' });
			}
		}
		let interval = setInterval(hasFocus, 500)
		return () => {
			if (interval) clearInterval(interval);
		}
	}, []);
	useEffect(() => {
		let refreshTS = false;
		let refreshCountDown = parseInt(settings.auto_refresh_seconds ?? -1);
		if (refreshCountDown > 2) {
			console.log('start refreshing', refreshCountDown)
			if (refreshTS) clearInterval(refreshTS);
			refreshTS = setInterval(() => {
				if (!isLoading && !globals.isLoading && document.hasFocus() && globals.inModal !== true) {
					dispatch({ type: 'refresh' })
				}
			}, refreshCountDown * 1000)
		}
		return () => {
			if (refreshTS) {
				console.log('stop refreshing')
				clearInterval(refreshTS);
			}
		}
	}, [settings.auto_refresh_seconds]);


	let selectedcount = (state.selectedIdsByPath[pathname] !== undefined) ? state.selectedIdsByPath[pathname].length : 0
	let right = isLoading ? 'Loading...' : settings.cnt ? settings.cnt + ' record' + (settings.cnt !== 1 ? 's' : '') : ''
	if (selectedcount > 0) right = isLoading ? 'Loading...' : selectedcount + ' selected'
	const columns = data.map((item, i) => <div className={"col-sm text-truncate " + (item.cls ? item.cls : '')} key={i} title={item.val}>{item.val}</div>);
	return <>
		{!state.json.data ? <div className="wrapper-content"></div> : ''}
		<div className={"wrapper-footer"} style={{ fontSize: '90%' }}>
			<div className={(docHasFocus ? "" : " hue-rotate")}>
				<div data-focus={docHasFocus} ref={ref} className={"row bg-dark text-white"}>
					{columns}
					<div className={"col-sm-1 text-right text-nowrap text-truncate" + (selectedcount > 0 ? ' bg-danger' : '')} title={'Current ID: ' + statusId + '\r\nFirst ID: ' + settings.first_id + '\r\nLast ID: ' + settings.last_id} >{right}</div>
				</div>
			</div>
		</div></>
}