function getcss(e,mreset) {
	let styles = window.getComputedStyle(e, null)
	let txt = '';
	for (let j = 0; j < styles.length; j++) {
		let cssObjProp = styles.item(j)
		let p = styles.getPropertyValue(cssObjProp);
		if(mreset && cssObjProp.match(/^margin/)) continue;
		if(mreset && cssObjProp.match(/^(max-width|width|max-height|height|max-block-size|max-inline-size|inline-size|block-size|max-height)/)) continue;
		if (p > '' && !p.match(/[']/)) {
			txt += cssObjProp + ": " + styles.getPropertyValue(cssObjProp) + ";";
		}
	}
	return txt;
}

export default function (action) {
	let { element, query_selector } = action;
	//console.log(query_selector);
	if (query_selector) element = document.querySelector(query_selector);
	if (!element) return;
	//if (!element) element = document.body;
	let clone = element.cloneNode(true);
	let height = element.scrollHeight
	let width = element.scrollWidth
	clone.style.cssText = getcss(element,true);
	//clone.innerHTML = '';
	//clone.innerHTML = element.innerHTML;

	const all = element.querySelectorAll("*");
	const all_clone = clone.querySelectorAll("*");
	for (let i = 0; i < all.length; i++) {
		let e = all[i];
		all_clone[i].style.cssText = getcss(e);
	}
	//console.log(all_clone);
	//return;
	var doc = document.implementation.createHTMLDocument('');
	//console.log(clone);
	doc.write(clone.outerHTML);
	doc.documentElement.setAttribute('xmlns', doc.documentElement.namespaceURI);
	let html = (new XMLSerializer).serializeToString(doc.body)
	//console.log(element.scrollHeight,html);
	html = html.replace(/\#/g, '%23');
	html = html.replace(/^<body /g, "<body style='margin:0;padding:0;overflow:visible;' ");
	var data = "data:image/svg+xml;charset=utf-8," + '<svg xmlns="http://www.w3.org/2000/svg" width="' + (width) + '" height="' + height + '">' +
		'<foreignObject width="100%" height="100%">' +
		html +
		'</foreignObject>' +
		'</svg>';

	var img = new Image();

	let canvas = document.createElement('canvas');
	let ctx = canvas.getContext('2d');
	canvas.width = width;
	canvas.height = height;


	img.onload = function () {
		ctx.drawImage(img, 0, 0);
		let imgData = canvas.toDataURL('image/png');
		const newWindow = window.open();
		newWindow.document.write('<img src="' + imgData + '" />');
		//URL.revokeObjectURL(url);
		//console.log(imgData);
	}
	img.src = data;
}