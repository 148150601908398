import React, { useContext, useState } from 'react';
import { DispatchContext } from "./Contexts";
import { useKey } from './Keyboard'
import { globals } from './globals';

export default function () {
    const [isam, setIsam] = useState('');
    const dispatch = useContext(DispatchContext);
    useKey("Escape", evt => {
        dispatch({ type: 'showisam', show: false })
    }, []);
    useKey("Enter", evt => {
        if(globals.setSelectedId) globals.setSelectedId(null);
        dispatch({ type: 'search_isam', isam })
        //dispatch({ type: 'querystring', querystring: { ise: isam ? isam : null, off: null, lid: null } })
    }, []);
    return <div className="isammodal"><div><input
        autoComplete="off"
        onChange={(evt) => setIsam(evt.target.value)}
        className="form-control" value={isam} autoFocus /></div></div>
}