import React, { useState } from 'react';
import { UseLayer } from "../Keyboard"

export default function DraftEditor({ data, setPostVal, isLoading, onArrowKeys, setLastFocus, lastFocus }) {
	const [hasFocus, setHasFocus] = useState(null);
	const [value, setValue] = useState(data.val);

	//console.log('hasFocus', hasFocus, value)
	const change = (evt) => {
		//console.log(evt.key);
		setPostVal({ name: data.name, value: evt.target.innerHTML })
		//setValue(evt.target.value);
	}
	/*
		const [editorState, setEditorState] = React.useState(() =>
			EditorState.createEmpty()
		);
	
		const editor = React.useRef(null);
		function focusEditor() {
			editor.current.focus();
		}
		console.log(editorState);
		*/
	return <>
		{hasFocus ? <UseLayer name='Editor' /> : ''}
		<div contentEditable={true}
			className={"form-control h-auto"}
			style={{ minHeight: "6em", cursor: "text" }}
			onFocus={() => setHasFocus(true)}
			onBlur={() => setHasFocus(false)}
			onInput={change}
			dangerouslySetInnerHTML={{ __html: value }}
		/>
	</>
	/*
	return (
		<div
			style={{ border: "1px solid black", minHeight: "6em", cursor: "text" }}
			onClick={focusEditor}
		>{hasFocus ? <UseLayer name='Editor' /> : ''}
			<Editor
				ref={editor}
				onFocus={() => setHasFocus(true)}
				onBlur={() => setHasFocus(false)}
				editorState={editorState}
				onChange={setEditorState}
				placeholder="Write something!"
			/>
		</div>
	);
	*/
}

