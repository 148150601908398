import React from 'react';
import { BarChart, Label, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function ({ def }) {
	let extra;
	try {
		extra = JSON.parse(def.extra);
	} catch (e) {
		console.log(e);
	}
	if (!extra) return null;
	let { data, colors, aspect, stacked } = extra;
	if (!(data && data.length)) return null
	let keys = Object.keys(data[0]);
	//
	colors = colors ?? ["#FF7400", "#FFAD69", "#FF8A29", "#BF5600", "#6E3200"]
	aspect = aspect ?? 2
	return <ResponsiveContainer aspect={aspect}>

		<BarChart
			data={data}
		>
			<XAxis dataKey={keys[0]} />
			<YAxis dataKey={stacked ? null : keys[1]} />
			{keys.length > 2 ? <YAxis yAxisId="right" orientation="right" /> : ''}
			<Tooltip />
			{keys.map((key, idx) => {
				if (idx === 0) return null;
				if (idx === 2 && stacked !== true) return <Bar key={key + idx} dataKey={key} yAxisId="right" fill={colors[idx]} />
				return <Bar key={key + idx} dataKey={key} stackId={stacked ? "a" : null} fill={colors[idx]} />
			})}
		</BarChart>
	</ResponsiveContainer>
}