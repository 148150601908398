import React from 'react';

export default function getHighlightedText(text, highlight) {
	//console.log(highlight)
	let hl = highlight.replace(/[^a-z0-9_\-@#.]+/gi,' ').trim().replace(/[ ]+/gi,'|');
	let reg = new RegExp("("+hl+")", 'gi')
	//console.log(hl)
	if(!text) return <span></span>
	const parts = text.split(reg);
	return <span> {parts.map((part, i) =>
		<span key={i} style={part.match(reg) ? { backgroundColor: '#ff0' } : {}}>
			{part}
		</span>)
	} </span>;
}