/*eslint no-unused-vars: "off"*/
import React, { useContext } from 'react';
//import { useFetch } from "./hooks";
import { StateContext } from "./Contexts";
import Header from './Header'
import Listview from './Listview'
//import Listedit from './Listedit'
//import Picklistview from './Picklistview'
import Handlerview from './Handlerview'
import Detailview from './Detailview'
import Editdetailview from './Editdetailview'
import Modalview from './Modalview'
import useFetch from "./useFetch";
import { useKeyLayer } from "./Keyboard"
import { globals } from './globals';

//import useKeyboardShortcut from './useKeyboardShortcut'

export default function Views() {
	useFetch();
	//useKeyboardShortcut(['ArrowDown'], () => console.log('Shift22 + H has been pressed.'))
	//useKeyboardShortcut(['ArrowUp'], () => console.log('ArrowUp has been pressed.'))
	const state = useContext(StateContext);
	//const isLoading = 
	const { cardChangesCounter, isLoading, settings } = state
	const views = state.json && state.json.views ? state.json.views : [];
	//console.log('views loading',state.isLoading);
	return views.map((view, i) => {
		const data = state.json[view.data];
		//let viewkey = view.data.replace(/^data_/, '')
		if (!data) return null;
		//console.log(view.comp, globals.selectedId);
		const def = state.json[view.data.replace(/^data/, 'def')];
		//const data = state.json[datakey][0] ?? [];
		if (view.comp === 'Actions') return <Header key={i} datakey={view.data} def={def} />
		//if (view.comp === 'Header') return <Header key={i} datakey={view.data} def={def} />
		if (view.comp === 'Listview') return <Listview key={i} datakey={view.data} def={def} />
		if (view.comp === 'Handlerview') return <Handlerview key={i} data={data} def={def} isLoading={isLoading} settings={settings} />
		if (view.comp === 'Detailview') return <Detailview key={i} datakey={view.data} data={data} def={def} />
		if (view.comp === 'Modalview') return <Modalview key={i} data={data} def={def} update={cardChangesCounter} />
		if (view.comp === 'Editdetailview') return <Editdetailview key={i} datakey={view.data} def={def} />
		//if (view.comp === 'Picklistview') return <Picklistview key={i} datakey={view.data} def={def} />
		return <div key={i}>No such comp: {view.comp}</div>
	})
}
