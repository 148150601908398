import React, { /*useState, useContext, useEffect, useRef*/ } from 'react';
import { useKey } from './Keyboard'

export default function ({ pages, page, dispatch, pathname }) {
	let maxPage = 0;
	const tabs = pages.map((item) => {
		maxPage = maxPage < item.page ? item.page : maxPage;
		return <Tab key={'pg' + item.page} item={item} dispatch={dispatch} page={page} pathname={pathname} />
	});
	const drop = (evt) => {
		let def_name = evt.dataTransfer.getData('def_name');
		let after = () => { dispatch({ type: 'reload' }) }
		if (maxPage>40) maxPage = 40;
		dispatch({ type: 'post', isLoading: true, pathname: pathname, search: '?edit=' + def_name, data: { "detail_page": maxPage + 1 }, after })
		evt.stopPropagation();
	}
	const dragover = (evt) => evt.preventDefault();


	return (
		<div className={"wrapper-header pl-0"}>
			<ul className="nav nav-tabs mt-2" draggable onDrop={drop} onDragOver={dragover}>
				{tabs}
			</ul>
		</div>
	);

}
function Tab({ item, dispatch, page, pathname }) {
	const gotopage = (evt) => {
		if (evt.target.blur) evt.target.blur();
		dispatch({ type: 'set_setting', key: 'updatedfield', value: item.fid });
	}
	//useKey(item.page === (page-1) ? 'PageUp' : false, gotopage);
	//useKey(item.page === (page+1) ? 'PageDown' : false, gotopage);
	useKey('Alt+' + ((item.page > 8 ? -1 : item.page) + 1 + ''), gotopage);
	const drop = (evt) => {
		let def_name = evt.dataTransfer.getData('def_name');
		let after = () => { dispatch({ type: 'reload' }) }
		let maxPage = item.page;
		if (maxPage>39) maxPage = 39;
		dispatch({ type: 'post', isLoading: true, pathname: pathname, search: '?edit=' + def_name, data: { "detail_page": maxPage }, after })
		evt.stopPropagation();
	}
	const dragover = (evt) => evt.preventDefault();
	//console.log(item)
	return (<li key={item.page} className="nav-item" draggable onDrop={drop} onDragOver={dragover} >
		<button
			onClick={gotopage}
			className={"btn btn-link mx-1 px-4 py-1 nav-link" + (item.page === page ? ' active' : '') + (item.is_mod ? ' modified' : '') + (item.is_valid === false ? ' not-valid' : '')} >
			{item.name}
		</button>
	</li>);



}